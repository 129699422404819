<template>
  <div>
    <p class="h4 pt-4 pb-4">批量添加权限</p>
    <span class="btn btn-primary btn-sm fileinput-button">
      <span>{{ !filename ? '选择文件' : '重选' }}</span>
      <input type="file" name="xlfile" id="xlf" @change="handleFile">
    </span>
    <span class="ml-2" v-show="filename"><i class="el-icon-document mr-1"></i>{{filename}}</span>
    <div class="mt-3">
      <el-input v-model="projectid" placeholder="请输入projectid" size="small" style="max-width: 200px"></el-input>
      <el-button type="success" style="margin-left: 10px;" size="small" @click="submit()" :disabled="!(tableData.length > 0 && projectid)">批量添加权限</el-button>
    </div>
    <el-table
      class="mt-4 mb-5"
      v-loading="loading"
      :data="tableData"
      border
      style="width: 100%">
      <el-table-column
        prop="projectid"
        label="projectid"
        min-width="180">
      </el-table-column>
      <el-table-column
        prop="username"
        label="username"
        min-width="180">
      </el-table-column>
      <el-table-column
        prop="puflag"
        label="puflag"
        min-width="180">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import axios from 'axios'
import $ from 'jquery'
const http = axios.create({
  headers: { 'X-Xsrftoken': $('meta[name=_xsrf]').attr('content') }
})

export default {
  data () {
    return {
      projectid: '',
      filename: '',
      tableData: [],
      loading: false
    }
  },
  created () {

  },
  methods: {
    handleFile (e) {
      const files = e.target.files
      if (files[0]) {
        this.filename = files[0].name
        this.readFile(files[0])
      }
    },
    readFile (file) {
      const vm = this
      const reader = new FileReader()
      reader.onload = function (e) {
        vm.loading = true
        const data = e.target.result // 获取表格
        const wb = XLSX.read(data, { type: 'binary' }) // 读取Excel信息
        const firstSheetName = wb.SheetNames[0] // 获取表一名称
        const worksheet = wb.Sheets[firstSheetName] // 获取表一
        const json = XLSX.utils.sheet_to_json(worksheet) // 解析成json格式
        vm.tableData = json
        vm.loading = false
      }
      reader.readAsBinaryString(file)
    },
    submit () {
      const vm = this
      const xls = document.getElementById('xlf')
      // projectid核对
      for (const i in vm.tableData) {
        if (vm.tableData[i].projectid !== vm.projectid) {
          vm.$notify({
            title: '警告',
            message: '表格中存在projectid不一致',
            type: 'warning'
          })
          return
        }
        vm.tableData[i].projectid = Number(vm.tableData[i].projectid)
        vm.tableData[i].puflag = Number(vm.tableData[i].puflag)
        vm.tableData[i].teamid = Number(vm.tableData[i].teamid)
      }
      // 异步请求
      http.post('/api/addtaskusers', {
        projectid: parseInt(vm.projectid),
        taskusers: vm.tableData
      }).then(function (response) {
        console.log(response)
        const rsp = response.data
        if (rsp.code === 0) {
          vm.$notify({
            title: '成功',
            message: rsp.message,
            type: 'success'
          })
          xls.value = ''
          vm.filename = ''
        } else {
          vm.$message({
            message: rsp.message,
            type: 'error'
          })
        }
      }
      )
    }
  }
}
</script>

<style scoped>
.fileinput-button {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.fileinput-button input {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  -ms-filter: "alpha(opacity=0)";
}
</style>
